<template>
	<div class="">

		<div class="row" v-if="loading">
			<div>
				<div class="center text-center p-4">
					<span class="fe fe-spinner">Loading..</span>
				</div>
			</div>
		</div>

		<div class="row" v-else>
			<div class="col-12" v-if="currentCreditSystem">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<h4>Update employee credit line</h4>
								<p class="text-muted">
									Credit line allows employers make available a maximum amount
									of money that there employee can spend on transport within a
									month
								</p>
							</div>
						</div>

						<div class="alert alert-danger" v-if="error">
							<span>{{ error }}</span>
						</div>

						<form @submit.prevent="updateCreditLine">
							<div class="form-group">
								<label for="">How much credit line do you want to give each
									employee?</label>
								<input v-model.number="form.amount" required type="number" name="amount" id="amount"
									class="form-control" />
							</div>

							<div class="form-group">
								<label for="">Which employees do you want to apply the credit to?</label>
								<select v-model="form.applicable_employee_kind" required name="applicable_employee_kind"
									id="applicable_employee_kind" class="custom-select">
									<option value="all">All Employees</option>
									<option value="all_except">All employees except some</option>
									<option value="some">Only some select employees</option>
								</select>
							</div>

							<div class="form-group" v-if="form.applicable_employee_kind != 'all'">
								<label>Select the Employees {{ form.applicable_employee_kind == "all_except" ? "to exempt" : "to include"
								}}</label>
								<credit-line-employee-selector @close="closeStaffSelector()" @show="openStaffSelector()"
									@changes-saved="updateData"
									:control-title="form.applicable_employee_kind === 'all_except' ? 'Employees to exempt' : 'Employees to include'"
									:company-id="companyId" :handle-update="false" :selector-open="showEmployeeSelector"
									:employees="form.applicable_employee_value">
									<template #default="{ open }">
										<span @click="open" :class="form.applicable_employee_value.length ? '' : 'text-success'">{{
											form.applicable_employee_value.length
												|| 'Click to add' }}</span>
									</template>
								</credit-line-employee-selector>
							</div>

							<div class="form-group __btnList">
								<div class="editBtnDiv">
									<button type="button" class="btn btn-dark" :disabled="creating" @click="updateUserList">
										{{ updating ? 'Updating..' : 'Update User List' }}
									</button>
								</div>
								<button type="submit" class="btn __btnStyle" :disabled="creating">
									{{ creating ? 'Updating..' : 'Update Credit Line' }}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { axiosInstance as axios } from '@/plugins/axios'
import { useRouter } from 'vue-router/composables'
import {ref, defineProps, onMounted} from 'vue'
import Swal from 'sweetalert2'
import CreditLineEmployeeSelector from '@/components/modules/transactions/CreditLineEmployeeSelector'
const props = defineProps({
  companyId: {
    required: true,
    type: [Number, String]
  }
})

const router = useRouter()
const error = ref(null)
const form = ref({
  applicable_employee_kind: 'all',
  applicable_employee_value: [],
  is_automatic: 0,
  amount: 0
})
const staffList = ref([])
const isLoading = ref(false)
const loading = ref(false)
const creating = ref(false)
const updating = ref(false)
const currentCreditSystem = ref(null)
const showEmployeeSelector = ref(false)

onMounted(() => {
  fetchCompanyCreditSystem(props.companyId)
})

  const fetchCompanyCreditSystem = async (companyId) => {
    if (loading.value) return

    try {
      loading.value = true
      const result = await axios.get(
        `/v1/corporates/${companyId}/credit-system`
      )
      currentCreditSystem.value = result.data

      form.value.amount = currentCreditSystem.value.amount
      form.value.applicable_employee_kind = currentCreditSystem.value.applicable_employee_kind
      form.value.applicable_employee_value = currentCreditSystem.value.applicable_employee
      form.value.is_automatic = currentCreditSystem.value.is_automatic
      loading.value = false
    } catch (e) {
      currentCreditSystem.value = null
      error.value = e.toString()
      if (e.response && e.response.status == 404) {
        needCreditSystem.value = true
      }
      loading.value = false
    }
  }

  const limitText = (count) => {
    return `and ${count} other staff`
  }

  const updateUserList = async () => {
    updating.value = true
    const applicableUserIds = form.value.applicable_employee_value.map(($) => $.id)

    try {
      await axios.patch(`/v1/credit-systems/${parseInt(props.companyId)}/applicable-employees`, {
        applicable_employee_value: applicableUserIds
      })
      updating.value = false
      await Swal.fire({
        icon: 'success',
        title: 'Updated successfully',
        text: 'Employees list updated successfully'
      })
    } catch (Error) {
      error.value = Error.toString()
      if (Error.response && Error.response.data && Error.response.data.message) {
        error.value = Error.response.data.message
      }
      updating.value = false
    }
  }

  const updateCreditLine = async () => {
    if (creating.value) return

    const result = await Swal.fire({
      icon: 'question',
      title: 'Please Confirm',
      text:
        'Are you sure you want to update this credit line to the applicable staff of this company?',
      showConfirmButton: true,
      showCancelButton: true
    })

    if (!result.isConfirmed) {
      return
    }

    error.value = null

    if (form.value.applicable_employee_kind == null || form.value.amount <= 0) {
      error.value = 'All fields are required'
      return
    }

    if (form.value.applicable_employee_kind != 'all' && (
      form.value.applicable_employee_value == null || !form.value.applicable_employee_value.length
    )) {
      error.value = 'All fields are required'
      return
    }

    creating.value = true
    let staffIds

    const payload = {
      applicable_employee_kind: form.value.applicable_employee_kind,
      is_automatic: form.value.is_automatic,
      amount: form.value.amount
    }

    if (form.value.applicable_employee_value && form.value.applicable_employee_value.length) {
      staffIds = form.value.applicable_employee_value.map((m) => m.id)
      payload.applicable_employee_value = staffIds
    }

    axios
      .patch(`/v1/credit-systems/${currentCreditSystem.value.id}`, payload)
      .then(() => {
        creating.value = false
        router.push({
          name: 'ShowCreditLine',
          params: {
            companyId: props.companyId
          }
        })
      })
      .catch((err) => {
        error.value = err.toString()
        if (err.response && err.response.data && err.response.data.message) {
          error.value = err.response.data.message
        }
        creating.value = false
      })
  }

  const asyncFindEmployee = (query) => {
    isLoading.value = true
    axios
      .get(`/v1/corporates/${props.companyId}/staff`, { params: { query } })
      .then((response) => {
        staffList.value = response.data.data.staff
        isLoading.value = false
      })
      .catch(() => {
        isLoading.value = false
        staffList.value = []
      })
  }

 const clearAll = () => {
    form.value.applicable_employee_value = []
  }

 const fullName = ({ fname, lname }) => {
    return `${fname} ${lname}`
  }

 const openStaffSelector = () => {
    showEmployeeSelector.value = true
  }

 const closeStaffSelector = () => {
    showEmployeeSelector.value = false
  }

 const updateData = (newEmployeeList) => {
    form.value.applicable_employee_value = newEmployeeList
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.__btnList {
  width: 100%;
  display: flex;
  flex-flow: row;
  margin-top: 2%;
}

.editBtnDiv {
  margin-right: 15px;
}

.__btnStyle {
  border: 1px solid #F2827A;
  color: #F2827A
}
</style>
